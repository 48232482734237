import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IErrors, IHistogramData, IItemsOptions } from '../../../core/interfaces';
import { GraphAndStateDataService } from '../../../core/services';
import { AxiosResponse } from 'axios';
import store from '../../../core/store';
import { selectScreenWidth } from '../../../core/selectors/dashboard/dashboardSelector';
import { selectGraphDataAll, selectGraphDataRangeAll } from '../../../core/selectors/graphData/graphDataSelector';
import { poolingGaps } from '../../../helpers';
import { graphDataMapperConstants } from '../../../core/constants/graphDataMapperConstants';
import { getAllSensor } from '../../../core/selectors/monitoringTree/minimapGetActiveSensorInTreeSelector';
import chunk from 'lodash/chunk';



/**
 * Graph data actions
 *
 * @type {Object}
 */
export const GraphDataAction = {

    /**
     * Show/hide state details popover
     *
     *
     * @return {Promise<Object>}
     * @param from
     * @param to
     */
    getHistogramData: (from: string, to: string): (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void => {

        const success = (graphDataMap: Map<number, IHistogramData>, showLoader: boolean) => {

            return {
                type: graphDataMapperConstants.GRAPH_DATA_MAPPER_SUCCESS,
                graphData: graphDataMap,
                showLoader: showLoader,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: graphDataMapperConstants.GRAPH_DATA_MAPPER_FAILURE,
            };
        }, service = new GraphAndStateDataService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            const state = store.getState(),
                screenWidth = selectScreenWidth(state),
                graphDataMap = selectGraphDataAll(state);

            let allSensorInTree = chunk(Array.from(getAllSensor(state).values()), 20);

            if (allSensorInTree.length === 0) {

                allSensorInTree = [[]];
            }
            // dispatch(success(graphDataMap, true));
            allSensorInTree.map((item, index) =>{

                const sensorId = item.map(sensor=> sensor.id);

                const argMain: IItemsOptions = {
                    from: from,
                    to: to,
                    numberOfDataPoints: screenWidth | 500,
                    sensors: sensorId,
                    units: [],
                };

                service.items(argMain)
                    .then(({ data }: AxiosResponse) => {
                        poolingGaps(data).then(poolingData => {

                            for (const value of poolingData) {
                                let showLoaderLogic = true;

                                if (value.type === 'graph') {
                                    showLoaderLogic = value.values.length > 0;
                                } else if (value.states) {
                                    showLoaderLogic = value.states.length > 0;
                                }

                                graphDataMap.set(value.id, { ...value, showLoader: showLoaderLogic });

                            }

                            dispatch(success(graphDataMap, index === allSensorInTree.length));
                        });
                    })
                    .catch((error) => {

                        dispatch(failure(service.errorHandler(error)));
                    });
            });
        };
    },

    /**
     * 
     * @param {string} from
     * @param {string} to
     * @return {(dispatch: ThunkDispatch<Record<string, unknown>, void, AnyAction>) => void}
     */
    getHistogramDataRange: (from: string, to: string): (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void  => {

        const successRange = (graphDataRangeMap: Map<number, IHistogramData>) => {

            return {
                type: graphDataMapperConstants.GRAPH_DATA_RANGE_MAPPER_SUCCESS,
                graphDataRange: graphDataRangeMap,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: graphDataMapperConstants.GRAPH_DATA_MAPPER_FAILURE,
            };
        }, service = new GraphAndStateDataService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            const state = store.getState(),
                screenWidth = selectScreenWidth(state),
                graphDataRangeMap = selectGraphDataRangeAll(state);

            let allSensorInTree = chunk(Array.from(getAllSensor(state).values()), 20);

            graphDataRangeMap.clear();

            if (allSensorInTree.length === 0) {

                allSensorInTree = [[]];
            }

            allSensorInTree.map(item =>{

                const sensorId = item.map(sensor=> sensor.id);

                const argMain: IItemsOptions = {
                    from: from,
                    to: to,
                    numberOfDataPoints: screenWidth | 500,
                    sensors: sensorId,
                    units: [],
                };

                service.items(argMain)
                    .then(({ data }: AxiosResponse) => {
                        poolingGaps(data).then(poolingData => {

                            for (const value of poolingData) {
                                // graphDataRangeMap.has(value.id) && graphDataRangeMap.delete(value.id);
                                let showLoaderLogic = true;

                                if (value.type === 'graph') {

                                    showLoaderLogic = value.values.length > 0;
                                } else if (value.states) {

                                    showLoaderLogic = value.states.length > 0;
                                }

                                graphDataRangeMap.set(value.id, { ...value, showLoader: showLoaderLogic });
                                // graphDataRangeMap.set(value.id, value);

                            }

                            dispatch(successRange(graphDataRangeMap));
                        });
                    })
                    .catch((error) => {

                        dispatch(failure(service.errorHandler(error)));
                    });

            });
           
        };
    },
};
