import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, AppBar, Toolbar, Badge, List, ListItem, ListItemIcon } from '@material-ui/core';

import { ReactComponent as Setting } from '../../core/ui/assets/images/icons/settings-v2.svg';
import { ReactComponent as AlertPanel } from '../../core/ui/assets/images/icons/alert-panel.svg';
import { ReactComponent as DropdownArrow } from '../../core/ui/assets/images/icons/dropdown-arrow.svg';
import { ReactComponent as UserIcon } from '../../core/ui/assets/images/icons/user.svg';

import {
    DashboardActions,
    FormActions,
    HmiPlayerActions,
    HmiSchemaAction,
    statesActions,
    UserActions,
} from '../../core/actions';
import { history } from '../../helpers';

import LeftNavItem from './LeftNavItem';

import './Header.scss';
import RightSidebar from '../Sidebar/RightSidebar';
import AlertSidebar from '../AlertSidebar/AlertSidebar';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { GraphActions } from '../../base/store/actions';
import { selectForm } from '../../core/selectors/form/formSelector';
import { selectRBAC } from '../../core/selectors/auth/authSelector';
import { selectNotificationCount } from '../../core/selectors/notification/notificationSelector';
import { selectHmiPlayerSchema } from '../../core/selectors/hmi/playerSelector';
import { selectHmiPlayerVisibility } from '../../core/selectors/hmi/visibilitySelector';
import { selectAppSettings } from '../../core/selectors/appSetting/appSettingSelector';
import { LayoutActions } from '../../core/actions/layout';
import { setUrl } from '../../core/helpers/historyHalper';

/**
 * Base styles for the Components
 *
 * @type {StylesHook<Styles<Theme, {}, string>>}
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.15), 0 1px 1px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
        zIndex: 1201,
        maxHeight: 40,
        position: 'fixed',
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    panel: {
        flexGrow: 1,
        width: '100%',
        paddingRight: '200px',
        marginRight: '-200px',
    },
    badge: {
        backgroundColor: '#eb444b',
        height: 14,
        width: 'auto',
        fontSize: 12,
        minWidth: 14,
        padding: 2,
        top: 2,
        right: 3,
    },
    itemIcon: {
        minWidth: 24,
        cursor: 'pointer',
    },
    paperRoot: {
        top: 40,
    },
}));

/**
 * Functional component Header
 *
 * @return {JSX.Element}
 */
const Header: FC = () => {

    const classes = useStyles(),
        [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null),
        [openAlert, setOpenAlert] = useState(false),
        isMenuOpen = Boolean(anchorEl);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { formName } = useSelector(selectForm);

    const notificationCount = useSelector(selectNotificationCount);

    const rbac = useSelector(selectRBAC);
    const schema = useSelector(selectHmiPlayerSchema);
    const isVisible = useSelector(selectHmiPlayerVisibility) && schema !== null,
        appSetting = useSelector(selectAppSettings);


    /**
     * Handler for the menu open action
     *
     * @param {React.MouseEvent<HTMLButtonElement>} event
     */
    const handleProfileMenuOpen = useCallback((event) => {

        setAnchorEl(event.currentTarget);

    }, [setAnchorEl]);

    /**
     * Handler for the menu close action
     */
    const handleMenuClose = useCallback(() => {

        dispatch(FormActions.toggle(true, ''));

        setAnchorEl(null);

    }, [dispatch, setAnchorEl]);

    /**
     * User logout action
     */
    const userLogout = useCallback(() => {

        dispatch(UserActions.logout());

        setUrl('/login');

    }, [dispatch]);

    /**
     * Route change handler
     *
     * @param {string} path
     */
    const handlerChangeRoute = useCallback((path) => {

        dispatch(FormActions.toggle(true, ''));

        if (path) {

            setUrl(path);
        }

    }, [dispatch]);

    /**
     * Sidebar Opening Handler
     */
    const handlerOpenAlertSidebar = useCallback((event) => {

        event.stopPropagation();

        dispatch(FormActions.toggle(false, ''));

        if (!openAlert) {

            dispatch(FormActions.toggle(openAlert, 'alert-sidebar'));

        } else {

            dispatch(FormActions.toggle(openAlert, ''));

            dispatch(GraphActions.deselectAlertGraph());
        }
    }, [dispatch, openAlert]);

    useEffect(() => {

        if (formName === 'alert-sidebar') {

            setOpenAlert(true);

        } else {

            setOpenAlert(false);

        }


    }, [formName]);

    /**
     * Resetting states and warnings
     */
    const resettingStatesAndAlerts = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        dispatch(statesActions.deselectAllStates());

        dispatch(GraphActions.deselectAlertGraph());

    }, [dispatch]);

    /**
     * Stop propagation
     */
    const stopPropagation = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        event.stopPropagation();

    }, []);

    const clickSettingCallBack = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {

        if (appSetting.hmi.isEnabled) {

            dispatch(GraphActions.toggleHMI(true));
            dispatch(HmiPlayerActions.setSchema(null));
            dispatch(LayoutActions.drawerPosition('right'));
            dispatch(HmiSchemaAction.editSchema(null,  true));
            dispatch(HmiPlayerActions.setRealTime(false));

        }

        window.sessionStorage.setItem('dashboardOnline', JSON.stringify(true));

        dispatch(DashboardActions.select({ id: 0, name: '' }));

    }, [appSetting, dispatch]);

    return (
        <React.Fragment>
            <div className={`header-custom-style ${isVisible ? 'visible-hmi': ''}`}
                 onClick={resettingStatesAndAlerts}
            >
                <Helmet>
                    <meta name="viewport" content="width=960, user-scalable=yes" />
                </Helmet>
                <AppBar
                    position="static"
                    color="default"
                    className={classes.root}
                >
                    <Toolbar className="horizontal" disableGutters>
                        <div className={classes.panel}>
                            <LeftNavItem handlerChangeRoute={handlerChangeRoute} />
                        </div>
                        <div className="right_nav">
                            <List
                                disablePadding
                            >
                                <ListItem
                                    selected={openAlert}
                                    component="div"
                                    onClick={handlerOpenAlertSidebar}
                                    className={`${openAlert ? 'alert-open' : ''}`}
                                >
                                    <Badge
                                        badgeContent={notificationCount | 0}
                                        color="primary"
                                        classes={{ colorPrimary: classes.badge }}
                                    >
                                        <AlertPanel className="svg_alert-panel" />
                                    </Badge>
                                </ListItem>
                                <ListItem
                                    component="div"
                                    style={{ paddingRight: 0 }}
                                    onClick={stopPropagation}
                                >
                                    <LanguageSwitcher variant="standard" class="header" defaultLanguage="" />
                                </ListItem>
                                <ListItem
                                    id="settingButton"
                                    selected={Boolean(history.location.pathname.match('setting'))}
                                    component={Link}
                                    onClick={clickSettingCallBack}
                                    to={rbac.can('setting:manage') ? '/setting/factory' : '/setting/password'}
                                >
                                    <Setting className="svg_setting" />
                                </ListItem>
                                <ListItem
                                    onClick={handleProfileMenuOpen}
                                    component="div"
                                >
                                    <ListItemIcon
                                        classes={{
                                            root: classes.itemIcon,
                                        }}
                                    >
                                        <div
                                            className="menu-avatar"
                                            style={{ position: 'relative', overflow: 'hidden' }}
                                        >
                                            <UserIcon style={{ fill: 'rgb(51, 51, 51)' }} />
                                        </div>
                                    </ListItemIcon>
                                    <DropdownArrow />
                                </ListItem>
                            </List>
                        </div>
                        {isMenuOpen ?
                            <Menu
                                anchorEl={anchorEl}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                id="user_nav"
                                keepMounted
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                open={isMenuOpen}
                                onClose={handleMenuClose}
                                style={{ top: 24 }}

                            >
                                <MenuItem key={1} className="menu-item" onClick={handleMenuClose} component={Link}
                                          to="/setting/password"
                                >{t('CHANGE_PASSWORD')}
                                </MenuItem>
                                <MenuItem key={2} className="menu-item" onClick={userLogout}>{t('LOGOUT')}</MenuItem>
                            </Menu>
                            : null}
                    </Toolbar>
                </AppBar>
            </div>
            <RightSidebar
                name="alert"
                openSidebar={openAlert}
                transitionDuration={{
                    enter: 150,
                    exit: 0,
                }}
            >
                <AlertSidebar sowContent={openAlert} />
            </RightSidebar>
        </React.Fragment>
    );
};

export default Header;
