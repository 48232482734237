import React, { FC, useEffect } from 'react';
import { useMainSocket } from '../../hooks/socket/useMainSocket';
import DashboardTimer from '../../components/DashboardTimer/DashboardTimer';
import { useRealTimeSensorDataSocket } from '../../hooks/socket/useRealTimeSensorDataSocket';
import { history } from '../../helpers';



interface IProps {
    children: React.ReactNode
}

const SocketProvider: FC<IProps> = ({ children }: IProps) => {

    useMainSocket();

    useRealTimeSensorDataSocket();


    useEffect(() => {

        const dashboardOnline = JSON.parse(window.sessionStorage.getItem('dashboardOnline') || 'false');

        if (dashboardOnline) {

            history.push(JSON.parse(window.sessionStorage.getItem('lastLocation') || '{}'));
        }

        window.onbeforeunload = () => {

            window.sessionStorage.setItem('lastLocation', JSON.stringify(window.location));
            window.sessionStorage.setItem('pageReloaded', JSON.stringify(true));
        };

    }, []);


    return (

        <DashboardTimer>
            {children}
        </DashboardTimer>
    );
};

export default SocketProvider;
