import React from 'react';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import { TimeLocaleDefinition } from 'd3';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import Selector from './Selector';

import './styles/Axis.scss';
import { RootState } from '../../../store';
import { INotification, IStateItem } from '../../../interfaces';
import { Merge } from '../../../../helpers/mergeType';
import { IHrState } from '../../../../modules/Hr/store/reducers';
import { selectHmiPlayerSchema } from '../../../selectors/hmi/playerSelector';
import { selectHmiPlayerVisibility } from '../../../selectors/hmi/visibilitySelector';
import {
    selectDrawerWidthWithDrawRules,
    selectPositionDrawer,
} from '../../../selectors/layout/responsiveDrawerSelector';
import { selectScreenWidth } from '../../../selectors/dashboard/dashboardSelector';
import { calcRealTimeIndentation } from '../../../selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { calculateScrollbarWidth } from '../../../helpers/calculateScrollbarWidthTreeSlider';
import { appConfig } from '../../../../config/appConfig';

/**
 * Add format duration function
 */
momentDurationFormatSetup.bind(moment());

/**
 * A graph axis with a ruler
 *
 * @class Axis
 */
class Axis extends React.PureComponent<any, any> {


    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: any) {

        super(props);

        this.smallScreen = (window.innerWidth / window.devicePixelRatio) < 460;

        this.structuralTreeHeight = 0;

        this.scale = d3.scaleTime()
            .range([0, this.props.screenWidth]);

        this.ru = {
            'dateTime': '%A, %e %B %Y г. %X',
            'date': '%d.%m.%Y',
            'time': '%H:%M:%S',
            'periods': [
                'AM',
                'PM',
            ],
            'days': [
                'воскресенье',
                'понедельник',
                'вторник',
                'среда',
                'четверг',
                'пятница',
                'суббота',
            ],
            'shortDays': [
                'вс',
                'пн',
                'вт',
                'ср',
                'чт',
                'пт',
                'сб',
            ],
            'months': [
                'января',
                'февраля',
                'марта',
                'апреля',
                'мая',
                'июня',
                'июля',
                'августа',
                'сентября',
                'октября',
                'ноября',
                'декабря',
            ],
            'shortMonths': [
                'янв',
                'фев',
                'мар',
                'апр',
                'май',
                'июн',
                'июл',
                'авг',
                'сен',
                'окт',
                'ноя',
                'дек',
            ],
        };

        this.en = {
            'dateTime': '%x, %X',
            'date': '%-m/%-d/%Y',
            'time': '%-I:%M:%S %p',
            'periods': [
                'AM',
                'PM',
            ],
            'days': [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ],
            'shortDays': [
                'Sun',
                'Mon',
                'Tue',
                'Wed',
                'Thu',
                'Fri',
                'Sat',
            ],
            'months': [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
            'shortMonths': [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ],
        };

        this.state = {
            statePosition: null,
            statePositionHover: null,
            alertPosition: null,
        };

        this.updateScale = this.updateScale.bind(this);
        this.getPositionElement = this.getPositionElement.bind(this);
        this.getTicks = this.getTicks.bind(this);
    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        this.getStructuralTreeHeight();
    }

    /**
     * Callback after data update
     *
     * @param prevProps
     */
    componentDidUpdate(prevProps: Readonly<any>) {

        if (this.props.height !== prevProps.height || this.props.mode !== prevProps.mode
            || this.props.minimapVisible !== prevProps.minimapVisible) {

            setTimeout(() => this.getStructuralTreeHeight(), 200);
        }

        // temporary change
        // if (this.props.screenWidth && (this.props.screenWidth !== prevProps.screenWidth || this.props.brushSelection !== prevProps.brushSelection)) {
        //
        //     this.scale = d3.scaleTime()
        //         .range([0, this.props.screenWidth]);
        //
        //
        //     //Rendering refresh after resizing
        //     this.forceUpdate();
        // }


        const { stateSelection, stateHovered, graphSelection, brushSelection } = this.props;

        if (brushSelection !== prevProps.brushSelection) {
            this.updateScale();
        }
        if (stateSelection !== prevProps.stateSelection || brushSelection !== prevProps.brushSelection) {

            this.getPositionElement(stateSelection.state, 'statePosition');
        }
        if (stateHovered !== prevProps.stateHovered || brushSelection !== prevProps.brushSelection) {

            this.getPositionElement(stateHovered.state, 'statePositionHover');
        }
        if (graphSelection !== prevProps.graphSelection || brushSelection !== prevProps.brushSelection) {

            this.getPositionElement(graphSelection.alert, 'alertPosition');
        }
    }

    componentWillUnmount() {

       const rootElement: HTMLElement | null = document.getElementById('root');

        rootElement && rootElement.classList.remove('with-sidebar');
    }
    /**
     * The X scale
     */
    private scale: d3.ScaleTime<number, number>;

    /**
     * Flag to determin a small device screen (less than 960 px width)
     *
     * @type {boolean}
     */
    private readonly smallScreen: boolean;

    /**
     * Structural Tree Height
     *
     * @type {number}
     * @private
     */
    private structuralTreeHeight: number;

    /**
     * Localization D3 RU
     * @private
     */
    private readonly ru: TimeLocaleDefinition;

    /**
     * Localization D3 EN
     * @private
     */
    private readonly en: TimeLocaleDefinition;


    /**
     * Get date format based on selected interval
     *
     * @param {Date} date
     * @param {boolean} monthGap
     *
     * @return {function}
     */
    multiFormat(date: Date, monthGap = false) {

        const language = localStorage.getItem('language');

        d3.timeFormatDefaultLocale(language === 'ru' ? this.ru : this.en);

        const formatMillisecond = d3.timeFormat('%H:%M:%S.%L'),
            formatSecond = d3.timeFormat('%H:%M:%S'),
            formatMinute = d3.timeFormat('%H:%M'),
            formatHour = d3.timeFormat('%H:%M'),
            formatDay = d3.timeFormat('%d %B %A'),
            formatWeek = d3.timeFormat('%d %B %A'),
            formatMonth = d3.timeFormat('%b'),
            formatYear = d3.timeFormat('%Y');

        return (d3.timeSecond(date) < date ? formatMillisecond
            : d3.timeMinute(date) < date ? formatSecond
                : d3.timeHour(date) < date ? formatMinute
                    : d3.timeDay(date) < date ? formatHour
                        : d3.timeMonth(date) < date || !monthGap ? (d3.timeWeek(date) < date ? formatDay : formatWeek)
                            : d3.timeYear(date) < date ? formatMonth
                                : formatYear)(date);
    }

    /**
     * Get height structural tree
     */
    getStructuralTreeHeight() {

        const getElement: any = document.getElementsByClassName('structure-tree'),
            root: any = document.querySelector(':root'),
            rootElement: any = document.getElementById('root');

        //TODO: What is 27 and 13? Move to constants
        this.structuralTreeHeight = getElement[0] ?
            getElement[0].classList.contains('monitoring-mode') ?
                getElement[0].offsetHeight + 27 : getElement[0].offsetHeight - 13 : 0;

        //TODO: What is 112? Move to constant
        root.style.setProperty('--sidebar-height', window.innerHeight > this.structuralTreeHeight + 112 ?
            '100%' : this.structuralTreeHeight + 85 + 'px');

        rootElement.classList.add('with-sidebar');

    }

    /**
     * Update scale with brush selection
     */
    updateScale() {

        const { brushSelection } = this.props;

        if (brushSelection) {
            this.scale.domain(brushSelection).range([0, this.props.screenWidth]);
        }
    }

    /**
     * Get ticks for the axis
     *
     * @return {Object[]}
     */
    getTicks() {

        const { brushSelection } = this.props;

        const output = [];

        let ticks = this.scale.domain(brushSelection).range([0, this.props.screenWidth - appConfig.correctionFactorForDrawingTheTimeline]).ticks();

        if (this.smallScreen) {

            ticks = this.scale.range([0, this.props.screenWidth]).ticks(Math.round(ticks.length / 2));
        }

        //a little crutch to fix QVF-229. Think about better solution when you have a time
        let monthGap = false;

        if (ticks.length > 2) {

            const firstTick = moment(ticks[0]),
                nextTick = moment(ticks[1]);

            if (!nextTick.isSame(firstTick, 'month') && firstTick.date() === 1 && nextTick.date() === 1) {

                monthGap = true;
            }
        }

        for (let i = 0; i < ticks.length; i++) {

            const position = this.scale(ticks[i]) as number;

            output.push({
                value: this.multiFormat(ticks[i], monthGap),
                position: position,
                width: i < ticks.length - 1 ? this.scale(ticks[i + 1]) as number - position : 'auto',
            });
        }

        return output;
    }

    /**
     * Render tick value
     *
     * @param {string} value
     *
     * @return {JSX.Element}
     */
    renderTickValue(value: string) {

        const segments = value.split(' ');

        if (segments.length === 3) {

            return <p>{segments.slice(0, 2).join(' ')}<span>{segments.pop()}</span></p>;
        }

        return <p>{value}</p>;
    }

    /**
     * Calc duration
     *
     * @param {Date} start
     * @param {Date} end
     * @return {number}
     */
    calcDuration(start: Date, end: Date): number {

        const [from, to] = this.props.brushSelection;

        const startTimestamp = start.getTime(),
            endTimestamp = end.getTime(),
            fromTimestamp = new Date(from).getTime(),
            toTimeStamp = new Date(to).getTime();


        return (endTimestamp >= toTimeStamp ? toTimeStamp : endTimestamp) - (startTimestamp <= fromTimestamp ? fromTimestamp : startTimestamp);
    }

    /**
     * Retrieving the size and position of the selected item
     */
    getPositionElement(value:{
        endTime: Date;
        startTime: Date;
        value: number;
        endTimeOriginal: Date;
        startTimeOriginal: Date;
    } | null, stateField: string) {

        if (value) {

            const { brushSelection, stateSelection, graphSelection, stateHovered } = this.props;

            const returnData = {
                left: 0,
                width: 0,
                visible: false,
                borderClass: '',
            };
            const [from, to] = brushSelection;

            if ((stateSelection && stateSelection.state) || (graphSelection && graphSelection.alert) || (stateHovered && stateHovered.state)) {

                if (new Date(value.startTime) > new Date(to) || new Date(value.endTime) < new Date(from)) {

                    this.setState({ [stateField]: returnData });

                    return;
                }

                const leftPointTime = new Date(value.startTime).getTime() > new Date(from).getTime() ?
                    new Date(value.startTimeOriginal).getTime() > new Date(from).getTime() ?
                        new Date(value.startTimeOriginal)
                        : new Date(value.startTime)
                    :
                    new Date(from);

                returnData.left = value ? this.scale(leftPointTime) as number : 0;
                returnData.visible = true;

                const right = value.endTime &&
                new Date(value.endTime).getTime() <= new Date(to).getTime() ? new Date(value.endTime) : new Date(to);

                returnData.width = value ? this.scale(right) as number - returnData.left : 0;

                if (returnData.width < 4 && returnData.width > 2) {

                    returnData.borderClass = 'border-1';

                }

                if (returnData.width < 2 && returnData.width >= 0) {

                    returnData.borderClass = 'border-left';

                }
            }

            this.setState({ [stateField]: returnData });

            return;
        }

        this.setState({ [stateField]: null });
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {


        const {
            minimapVisible = true,
            graphSelection,
            screenWidth,
            stateHovered,
            visibleSideBar = localStorage.getItem('sidebar'),
            currentState,
            stateSelection,
            selectionAlertRule,
            hoveredAlertRule,
            realTimeIndentation,
        } = this.props;

        const {
            statePosition,
            statePositionHover,
            alertPosition,
        } = this.state;


        const timeAxisWrapStyle = {
            width: screenWidth + realTimeIndentation + calculateScrollbarWidth() + 'px',
            left: visibleSideBar? 76: 320,
        };

        return (
            <React.Fragment>
                <div className={minimapVisible ? 'time-axis-wrap active-mini-map' : 'time-axis-wrap'}
                    style={timeAxisWrapStyle}
                >
                    <div className="time-axis">

                        {this.getTicks().map((tick, index) => (
                            <div key={index}>
                                <div className="tick" style={{ left: tick.position, width: tick.width }}>
                                    {this.renderTickValue(tick.value)}
                                </div>
                                <div className="dashed-line" style={{ left: tick.position }} />
                            </div>
                        ))}
                    </div>
                    {statePosition && statePosition.visible && currentState ?
                        <Selector
                            start={moment(currentState.startTime).format('HH:mm:ss')}
                            end={moment(currentState.endTime).format('HH:mm:ss')}
                            duration={moment.duration(this.calcDuration(new Date(currentState.startTime), new Date(currentState.endTime))).format('h[h] m[m] s[s]')}
                            left={statePosition.left < 0 ? 0 : statePosition.left}
                            width={statePosition.width}
                            typeName={`select ${statePosition.borderClass}`}
                        /> : null
                    }
                    {!currentState && stateSelection.state && statePosition?.visible ?
                        <Selector
                            start={moment(stateSelection.state.startTime).format('HH:mm:ss')}
                            end={moment(stateSelection.state.endTime).format('HH:mm:ss')}
                            duration={moment.duration(this.calcDuration(new Date(stateSelection.state.startTime), new Date(stateSelection.state.endTime))).format('h[h] m[m] s[s]')}
                            left={statePosition.left < 0 ? 0 : statePosition.left}
                            width={statePosition.width}
                            typeName={`select ${statePosition.borderClass}`}
                        /> : null
                    }
                    {stateHovered && stateHovered.state && statePositionHover?
                        <Selector
                            start={moment(stateHovered.state.startTime).format('HH:mm:ss')}
                            end={moment(stateHovered.state.endTime).format('HH:mm:ss')}
                            duration={moment.duration(new Date(stateHovered.state.endTime).getTime() - new Date(stateHovered.state.startTime).getTime()).format('h[h] m[m] s[s]')}
                            left={statePositionHover.left}
                            width={statePositionHover.width}
                            typeName={'hover'}
                        /> : null
                    }
                    {selectionAlertRule && alertPosition && alertPosition.visible?
                        <Selector
                            start={moment(graphSelection.alert.startTime).format('HH:mm:ss')}
                            end={moment(graphSelection.alert.endTime || new Date()).format('HH:mm:ss')}
                            duration={moment.duration(new Date(graphSelection.alert.endTime || new Date()).getTime() -  new Date(graphSelection.alert.startTime).getTime()).format('H[h] m[m] s[s]')}
                            left={alertPosition.left < 0 ? 0 : alertPosition.left}
                            width={alertPosition.width}
                            typeName={`select ${alertPosition.borderClass}`}
                        /> : null
                    }

                    {hoveredAlertRule ?
                        <Selector
                            typeName={'hover'}
                            start={moment(graphSelection.alertHover.startTime).format('HH:mm:ss')}
                            end={moment(graphSelection.alertHover.endTime || new Date()).format('HH:mm:ss')}
                            duration={moment.duration(new Date(graphSelection.alertHover.endTime || new Date()).getTime() -  new Date(graphSelection.alertHover.startTime).getTime()).format('H[h] m[m] s[s]')}
                            left={graphSelection.positionHover.left}
                            width={graphSelection.positionHover.width}
                        /> : null
                    }
                </div>
            </React.Fragment>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: Merge<RootState | { hr: IHrState }>) => {

    const {
        graphMinimapBrush,
        graphMinimapVisibility,
        graphStructuralTreeVisibility,
        graphHistogramHeight,
        stateSelection,
        graphSelection,
        dashboard,
        stateHovered,
        hr,
    } = state;

    const { sensorsData, sensorsDataRange } = dashboard;
    const { hrMode = false } = stateSelection;

    const { brushRule } = graphMinimapBrush;

    let sensorData: any;
    let sensorDataCurrentState: IStateItem = stateSelection.state;

    if (stateSelection && stateSelection.states && !hrMode) {

        sensorData = (!brushRule ? sensorsData : sensorsDataRange).find((value) =>
            stateSelection.states &&
            value.sensorId === stateSelection.states.sensorId,
        );
    }

    if (stateSelection && stateSelection.states && hrMode) {
        const { monitoringTreeState } = hr;
        const { hrData, hrDataRange } = monitoringTreeState;

        sensorData = (!brushRule ? hrData : hrDataRange).find((value) =>
            stateSelection.states &&
            value.id === stateSelection.states.id &&
            value.type === 'state',
        );
    }

    if (sensorData && stateSelection.state) {

        sensorDataCurrentState = sensorData.states.find((d: IStateItem)=>
            (new Date(d.startTimeOriginal).getTime() === new Date(stateSelection.state.startTimeOriginal).getTime() &&
            new Date(d.endTimeOriginal).getTime() === new Date(stateSelection.state.endTimeOriginal).getTime() && !hrMode) ||
            (hrMode && stateSelection.state.id === (d as any).id),
        );
    }

    const brushSelection = graphMinimapBrush.selection;

    const selectionAlertRule = graphSelection && graphSelection.alert && graphSelection.position?.width &&
        new Date(brushSelection[0]).getTime() < new Date((graphSelection.alert as unknown as INotification).endTime || new Date()).getTime() &&
        new Date(brushSelection[1]).getTime() > new Date((graphSelection.alert as unknown as INotification).startTime).getTime();

    const hoveredAlertRule = graphSelection && graphSelection.alertHover && graphSelection.positionHover?.width &&
        new Date(brushSelection[0]).getTime() < new Date((graphSelection.alertHover as unknown as INotification).endTime || new Date()).getTime() &&
        new Date(brushSelection[1]).getTime() > new Date((graphSelection.alertHover as unknown as INotification).startTime || new Date()).getTime();

    const schema = selectHmiPlayerSchema(state);
    const drawWidth = selectDrawerWidthWithDrawRules(state);

    const isVisible = selectHmiPlayerVisibility(state) && schema !== null,
        anchor: 'right' | 'bottom' = selectPositionDrawer(state) as 'right' | 'bottom',
        realTimeIndentation = calcRealTimeIndentation(state),
        screenWidth = selectScreenWidth(state) - (isVisible && anchor === 'right' ? drawWidth : 0)
            - realTimeIndentation;

    return {
        brushSelection,
        minimapVisible: graphMinimapVisibility.visible,
        visibleSideBar: graphStructuralTreeVisibility.visible,
        height: graphHistogramHeight.height,
        mode: graphHistogramHeight.mode,
        screenWidth: screenWidth,
        stateSelection,
        graphSelection,
        stateHovered,
        sensorData: sensorData,
        currentState: sensorDataCurrentState,
        selectionAlertRule,
        hoveredAlertRule,
        realTimeIndentation,
    };
};

export default React.memo(connect(mapStateToProps)(Axis));
