import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    INotification,
} from '../../core/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { NotificationAction, statesActions } from '../../core/actions';
import {
    AutoSizer,
} from 'react-virtualized';
import { GraphActions } from '../../base/store/actions';

import './AlertSidebar.scss';
import * as d3 from 'd3';
import {
    calcRealTimeIndentation,
    selectBrushSelection,
} from '../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectScreenWidth } from '../../core/selectors/dashboard/dashboardSelector';
import { selectAllNotification, selectNotificationCount } from '../../core/selectors/notification/notificationSelector';
import moment from 'moment';
import DynamicList, { createCache } from 'react-window-dynamic-list';
import { selectRBAC } from '../../core/selectors/auth/authSelector';
import AlertItemForm from './AlertItemForm';
import { selectGraphPeriodRange } from '../../core/selectors/graphPeriod/graphPeriodSelector';
import {
    selectGraphSelectionAlert,
} from '../../core/selectors/graphSelection/graphSelectionSelector';
import { appConfig } from '../../config/appConfig';

const cacheDC = createCache();

const scaleTime = d3.scaleTime();
interface INotificationWitIndex extends INotification{
    index: number;
}


const AlertSidebar: React.FC<any> = (...props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [list, setList] = useState<Map<number, INotificationWitIndex>>(new Map());
    const [notificationSelect, setNotificationSelect] = useState<INotification | undefined>(undefined);
    const [showCommentInput, setShowCommentInput] = useState<number | undefined>(undefined);
    const [selectedAlert, setSelectedAlert] = useState<number | undefined>(undefined);
    const [scrollToIndex, setScrollToIndex] = useState<number | undefined>(undefined);

    const dynamicListRef: React.MutableRefObject<import('react-window').VariableSizeList<any> | undefined> | undefined = useRef(undefined);

    const unRidNotification = useSelector(selectNotificationCount),
        selection = useSelector(selectGraphPeriodRange),
        rbac = useSelector(selectRBAC),
        notification = useSelector(selectAllNotification),
        selectionRange = useSelector(selectBrushSelection),
        realTimeIndentation = useSelector(calcRealTimeIndentation),
        screenWidth = useSelector(selectScreenWidth)
            - realTimeIndentation - appConfig.correctionFactorForDrawingTheTimeline,
        notificationSelectState = useSelector(selectGraphSelectionAlert);

    useEffect(() => {

        if (!selection && list.size === 0) {

            dispatch(NotificationAction.list(moment().subtract({ d: 1 }).toDate(), new Date()));

        } else if (selection) {

            dispatch(NotificationAction.list(selection.startDate, selection.endDate));

        }

        return () => {

            dispatch(GraphActions.unHoveredAlertGraph());
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selection]);

    useEffect(() => {

        if (list.size !== notification.length) {

            setList(new Map(notification.map((nt, index)=>[nt.id, { ...nt, index: index }])));
            if (!showCommentInput) {

                dynamicListRef.current && dynamicListRef.current.resetAfterIndex(0, true);

            }

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification, setList]);

    useEffect(() => {

        if (
            (notificationSelectState && notificationSelectState.id !== notificationSelect?.id) 
            || (notificationSelectState && !selectedAlert)
        ) {

            setScrollToIndex(list.get(notificationSelectState?.id || 0)?.index);
            setSelectedAlert(notificationSelectState.id);
        }

        if (notificationSelect && !notificationSelectState) {
            setSelectedAlert(undefined);
            setShowCommentInput(undefined);
        }

    }, [notificationSelectState, setSelectedAlert, setShowCommentInput, setScrollToIndex]);

    const markAllAsRead = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        dispatch(NotificationAction.markAllAsReadAction());

    }, [dispatch]);


    useEffect(() => {

        if (scrollToIndex && dynamicListRef?.current && !showCommentInput) {

            // setTimeout(() => {
            cacheDC.clearCache();
            scrollToIndex && dynamicListRef.current.scrollToItem(scrollToIndex, 'start');
            // }, 0);
        }

    }, [scrollToIndex, dynamicListRef, showCommentInput]);

    const operands = {
        '=': '=',
        '!=': '≠',
        '>': '>',
        '<': '<',
        '>=': '≥',
        '<=': '≤',
    };

    /**
     * Click handler
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
     * @param {number} index
     */
    const handleListItemClick =(
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedAlert(index);

    };

    /**
     * Row render click handler
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
     */
    const renderRowClickHandler = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        const index = event.currentTarget.tabIndex;

        handleListItemClick(event, index || 0);

        if (showCommentInput && showCommentInput !== index) {

            setShowCommentInput(undefined);
        }

        if (list.get(index)?.isNew && index) {

            dispatch(NotificationAction.markAsReadAction({ ...list.get(index) as INotification, isNew: false }));
        }

        if (!showCommentInput || showCommentInput !== notificationSelect?.id) {

            dispatch(GraphActions.unHoveredAlertGraph());
            dispatch(statesActions.deselectAllStates());
        }

        if (selectionRange && list.has(index)&& !showCommentInput) {

            scaleTime.range([0, screenWidth])
                .domain(selectionRange);

            const x1 = scaleTime(new Date(list.get(index)!.startTime)) || 0,
                x2 = scaleTime(new Date(list.get(index)!.endTime || selectionRange[1]));
            const width = (x2 || 0) - (x1 || 0);

            if (index || 0) {

                dispatch(GraphActions.selectAlertGraph({ ...list.get(index) as INotification, isNew: false }, {
                    left: x1,
                    width: width,
                }));
            }
        }

        setScrollToIndex(undefined);
        list.has(index) && setNotificationSelect(list.get(index) as INotification);


    }, [
        dispatch,
        list,
        notificationSelect?.id,
        scaleTime,
        screenWidth,
        selectionRange,
        showCommentInput,
    ]);

    /**
     * On Mouse Enter Row
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
     */
    const onMouseEnterRow = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        const index = event.currentTarget.tabIndex;
        //
        const notification: INotification = list.get(index) as INotification;

        if (selectedAlert !== notification?.id || !selectedAlert || notification) {

            if (selectionRange) {
                scaleTime.range([0, screenWidth])
                    .domain(selectionRange);
                const [from, to] = selectionRange,
                    x1CorrectTime = new Date(notification.startTime).getTime() < new Date(from).getTime() ?
                        new Date(from) : new Date(notification.startTime);

                const x1 = scaleTime(x1CorrectTime) as number,
                    x2 = scaleTime(new Date(notification.endTime || to)) as number;
                const width = (x2) - (x1);

                dispatch(GraphActions.hoveredAlertGraph(notification, {
                    left: x1,
                    width: width,
                }));
            }

            setScrollToIndex(undefined);
        }

    }, [
        dispatch,
        list,
        scaleTime,
        screenWidth,
        selectedAlert,
        selectionRange,
    ]);

    /**
     * On Mouse Leave from body
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
     */
    const onMouseLeaveBody=useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>)=> {

        event.preventDefault();
        // setNotificationHover(undefined);
        // setNotificationSelect(undefined);

    }, []);

    /**
     * On mouse leave side bar.
     */
    const onMouseLeaveSideBar = useCallback(() => {

        dispatch(GraphActions.unHoveredAlertGraph());

        dispatch(statesActions.deselectAllStates());

    }, [dispatch]);

    /**
     * Added reset for selected edit item.
     *
     * @param props
     */
    const onScrollResetFunction = useCallback((props: any) => {

        if (showCommentInput) {

            setShowCommentInput(undefined);

        }
    }, [showCommentInput, setShowCommentInput]);

    /**
     * Update comment action
     * @type {(data: INotification) => void}
     */
    const updateComment = useCallback((data: INotification) => {

        dispatch(NotificationAction.updateCommentAction(data));

    }, [dispatch]);

    /**
     * Set Show Component Input
     *
     * @param {number | undefined} itemId
     */
    const setShowCommentInputCallback = useCallback((itemId: number | undefined) => {

        setShowCommentInput(itemId);

        if (!itemId) {
            const resetAfterIndexTimeout = setTimeout(() => {

                const indexArr = list.get(itemId || 0)?.index || 0;

                dynamicListRef.current && dynamicListRef.current.resetAfterIndex(indexArr > -1 ? indexArr : 0, true);

                clearTimeout(resetAfterIndexTimeout);

            }, 20);

        }
    }, [setShowCommentInput, dynamicListRef, list]);

    return (
        <React.Fragment>
            <div
                className="wrap-alert-sidebar"
                onMouseLeave={onMouseLeaveSideBar}
            >
                <div className="header">{t('ALERTS')}</div>
                {unRidNotification > 0 &&
                <div className="read-all">
                    <div
                        className="read-all-btn"
                        onClick={markAllAsRead}
                    >
                        {t('READ_ALL')}
                    </div>
                </div>
                }
                <div
                    className="body"
                    onMouseLeave={onMouseLeaveBody}
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <DynamicList
                                ref={dynamicListRef}
                                data={notification}
                                cache={cacheDC}
                                height={height}
                                width={width}
                                recalculateItemsOnResize={{ width: false, height: false }}
                                lazyMeasurement
                                onScroll={onScrollResetFunction}
                            >
                                {({ index, style }) => (
                                    <>
                                        <div
                                            style={style}
                                            className={`alert-item ${selectedAlert === notification[index].id ? 'selected' : ''} ${showCommentInput === notification[index].id ? 'edit' : ''}`}
                                            onClick={renderRowClickHandler}
                                            onMouseEnter={onMouseEnterRow}
                                            tabIndex={notification[index].id}
                                        >
                                            <div
                                                className={`alert-item--wrap ${notification.length - 1 === index && notification.length > 2 ? 'last-item' : ''}`}
                                            >
                                                <div className="alert-item--body">
                                                    <div className="alert-item--path">
                                                        <div className="text">
                                                            ...{`/${notification[index].unit || ''}/${notification[index].sensor || ''}/`}
                                                            <span className={'param'}>
                                                                    {`${notification[index].um || ''} ${notification[index].operator ? operands[notification[index].operator] : ''}`}
                                                                <span
                                                                    style={{ fontStyle: 'italic' }}
                                                                >
                                                                        {` ${notification[index].value}`}
                                                                </span>
                                                                <span className={'for'}>
                                                                    {' ' + t('FOR') + ' '}
                                                                </span>
                                                                {notification[index].threshold < 60 ?
                                                                    <span style={{ fontStyle: 'italic' }}>
                                                                        {(notification[index].threshold)}
                                                                        {' ' + t('SEC')}
                                                                    </span>
                                                                    : <span style={{ fontStyle: 'italic' }}>
                                                                        {(notification[index].threshold / 60).toFixed(1)}
                                                                        {' ' + t('MINUTES')}
                                                                      </span>
                                                                }
                                                            </span>
                                                        </div>
                                                        {notification[index]?.isNew ?
                                                            <span className={'read-icon'} /> : null}
                                                    </div>
                                                    <AlertItemForm
                                                        dataAlert={notification[index]}
                                                        rbac={rbac}
                                                        showCommentInput={showCommentInput === notification[index].id}
                                                        setShowCommentInput={setShowCommentInputCallback}
                                                        updateComment={updateComment}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </DynamicList>
                        )}
                    </AutoSizer>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AlertSidebar;
