import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as ArrowExpanded } from '../../assets/images/icons/arrow-expanded.svg';
import './styles/RangeDatePicker.scss';

import moment, { Moment } from 'moment';
import { connect } from 'react-redux';
import { DashboardActions, HmiPlayerActions } from '../../../actions';
import Menu from '@material-ui/core/Menu';
import { appConfig } from '../../../../config/appConfig';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RootState } from '../../../store';
import { GraphActions } from '../../../../base/store/actions';
import { isMobile } from 'react-device-detect';
import { DateRangeIcon } from '@material-ui/pickers/_shared/icons/DateRangeIcon';

/**
 * Default interface
 *
 */
interface IState {
    dateRangePicker: {
        selection: {
            startDate: Date,
            endDate: any,
        },
    },
    start: Moment,
    end: Moment,
    toggle: boolean,
    anchorEl: null | HTMLElement
}


/**
 * DateRangePicker component
 *
 * @class DateRangePicker
 */
class DateRangePicker extends Component<any & WithTranslation, IState> {

    constructor(props: any & WithTranslation) {
        super(props);

        this.applyCallback = this.applyCallback.bind(this);

        this.handleClick = this.handleClick.bind(this);

        this.handleClose = this.handleClose.bind(this);
    }
    /**
     * The default state of the component
     */
    readonly state: IState = {
        dateRangePicker: {
            selection: {
                startDate: new Date(this.props.minimapRange.startDate),
                endDate: new Date(this.props.minimapRange.endDate),
            },
        },
        start: moment(),
        end: moment()
            .add(5, 'days')
            .subtract(1, 'second'),
        toggle: false,
        anchorEl: null,
    };

    /**
     * Component Update
     *
     * @param {Object} prevProps
     * @param {Object} prevState
     */
    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<IState>): void {

        if (this.state.dateRangePicker !== prevState.dateRangePicker) {

            this.props.rangeSelect(this.checkingDateEquality(this.state.dateRangePicker.selection));

            this.props.setDashboardOffline();

            this.props.setOffCurrentTime();
            this.props.realTimeHmiPlayer(false);
            this.props.stopHmiPlayer();
        }

        if (prevProps.minimapRange !== this.props.minimapRange && this.props.minimapRange) {

            this.checkMinMaxRangeDate(this.props.minimapRange);
        }


    }


    checkMinMaxRangeDate(minimapRange: any) {

        const { dateRangePicker } = this.state;
        const periodDay = moment(minimapRange.endDate).diff(moment(minimapRange.startDate), 'days');

        if (moment().subtract({ y: appConfig.minimumYear }).isAfter(minimapRange.startDate)) {

            minimapRange.startDate = moment().subtract({ y: appConfig.minimumYear }).toDate();
            minimapRange.endDate = moment(minimapRange.startDate).add({ d: periodDay }).toDate();

        }

        dateRangePicker.selection = minimapRange;

        this.setState({ dateRangePicker });
    }

    /**
     * Checking Date Equality
     *
     * @param {{startDate: Date, endDate: any}} data
     * @return {{startDate: Date, endDate: any}}
     */
    checkingDateEquality(data: { startDate: Date, endDate: any }): { startDate: Date, endDate: any } {
        
        if (moment(data.startDate).isSame(data.endDate)) {

            if (moment(data.endDate).isAfter(new Date())) {

                return {
                    endDate: data.startDate,
                    startDate: moment(data.startDate).subtract({ 'minute': 5 }).toDate(),
                };

            } else {

                return {
                    endDate: data.endDate,
                    startDate: moment(data.startDate).subtract({ 'minute': 5 }).toDate(),
                };

            }
        }

        return data;
    }

    /**
     * Opening handler DatePicker
     * @param {MouseEvent<HTMLButtonElement>} event
     */
    handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({ anchorEl: event.currentTarget });
    }


    /**
     * Close handler DatePicker
     */
    handleClose() {

        this.setState({ anchorEl: null });
    }



    applyCallback(startDate: Moment, endDate: Moment) {
        
        this.setState({
            ...this.state,
            dateRangePicker: {
                selection: {
                endDate: endDate.toDate(),
                startDate: startDate.toDate(),
                    },
            },
            anchorEl: null,
        });
    }

    rangeCallback(index: any, value: any) {
        // callback for range

    }


    renderStandalone(ranges: any, local: { format: string,  sundayFirst: boolean }, maxDate: Moment, descendingYears: boolean) {

         const { startDate, endDate } = this.state.dateRangePicker.selection;

         const newStartDay = moment(startDate)
             .set('hours', 0)
             .set('minutes', 0)
             .set('seconds', 0)
             .set('milliseconds', 0);
         let newEndTime  = endDate;

        if (endDate >= new Date()) {

            newEndTime = moment();

        } else {

            newEndTime = moment(newEndTime)
                .set('hours', 23)
                .set('minutes', 59)
                .set('seconds', 0)
                .set('milliseconds', 0);
        }

        return (
            <div id="DateTimeRangeContainer">
                <DateTimeRangeContainer
                    ranges={ranges}
                    start={newStartDay}
                    end={newEndTime}
                    local={local}
                    maxDate={(maxDate)}
                    applyCallback={this.applyCallback}
                    rangeCallback={this.rangeCallback}
                    descendingYears={descendingYears}
                    years={[moment().subtract({ 'year': 10 }).get('year'), moment().get('year')]}
                    standalone
                    style={{
                        fromDate: {
                            backgroundColor: '#4092F5',
                        },
                        toDate: {
                            backgroundColor: '#4092F5',
                        },
                        calendarCell: { color: '#1d2429', outline: 'none' },
                        customRangeButtons: { backgroundColor: 'rgb(255, 255, 255)', borderColor: 'transparent', color: '#333333', height: 40, lineHeight: '40px' },
                        customRangeSelected: { backgroundColor: '#4092F5', borderColor: '#4092F5', height: 40, lineHeight: '40px', outline: 'none' },
                        standaloneLayout: { display: 'flex', maxWidth: 'fit-content' },
                        betweenDates: {
                            backgroundColor: '#4092F5',
                            color: '#ffffff',
                        },
                    }}
                />
            </div>
        );
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t }=this.props;

        const now = new Date();

        const start = moment(
            new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0),
        );

        const end = moment(start)
            .add(1, 'days')
            .subtract(1, 'seconds');

        const ranges = {
            [this.props.t('TODAY_ONLY')]: [moment(start), moment(end)],
            [this.props.t('YESTERDAY_ONLY')]: [
                moment(start).subtract(1, 'days'),
                moment(end).subtract(1, 'days'),
            ],
            [`1 ${this.props.t('WEEK')}`]: [moment(start).subtract(7, 'days'), moment(end)],
            [`1 ${this.props.t('MONTH')}`]: [moment(start).subtract(1, 'months'), moment(end)],
        };

        if (!appConfig.demoAccount) {
            ranges[`1 ${this.props.t('YEAR')}`] = [moment(start).subtract(1, 'years'), moment(end)];
        }

        const local = {
            format: 'DD/MM/YYYY  HH:mm',
            sundayFirst: false,
            fromDate: this.props.t('FROM_DATE'),
            toDate: this.props.t('TO_DATE'),
            days: [t('MO'), t('TU'), t('WE'), t('TH'), t('FR'), t('SA'), t('SO')],
            months: [
                t('JANUARY'),
                t('FEBRUARY'),
                t('MARCH'),
                t('APRIL'),
                t('MAY'),
                t('JUNE'),
                t('JULY'),
                t('AUGUST'),
                t('SEPTEMBER'),
                t('OCTOBER'),
                t('NOVEMBER'),
                t('DECEMBER'),
            ],
            apply: this.props.t('APPLY'),
        };

        const maxDate = moment(end);

        const language = localStorage.getItem('language');

        if (language) {

            moment.locale(language);

        }
        return (
            <React.Fragment>
                <div className="datebox">
                    <span className={`datebox-value ${isMobile? 'mobile': ''}`} onClick={this.handleClick}>
                        {isMobile?
                            <DateRangeIcon htmlColor={'#4092F5'} />
                        :
                            <>
                                {moment(this.state.dateRangePicker.selection.startDate).format('D MMM') + ' '}
                                &mdash;
                                {' ' + moment(this.state.dateRangePicker.selection.endDate).format('D MMM')}
                            </>
                        }
                    </span>
                    <IconButton color="primary" component="span" onClick={this.handleClick}>
                        <ArrowExpanded />
                    </IconButton>
                    <Menu
                        id="date-range-picker-menu"
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                        {this.renderStandalone(ranges, local, maxDate, false)}
                    </Menu>
                </div>
            </React.Fragment>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { graphPeriod, dashboard } = state;
    const { range } = graphPeriod;

    return {
        minimapRange: range,
        dashboardOnline: dashboard.online,
        screenWidth: dashboard.screenWidth,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    rangeSelect: GraphActions.rangeSelect,
    resetSelection: GraphActions.resetSelection,
    setDashboardOffline: DashboardActions.setOffline,
    setOffCurrentTime: DashboardActions.setOffCurrentTime,
    realTimeHmiPlayer: HmiPlayerActions.setRealTime,
    stopHmiPlayer: HmiPlayerActions.stop,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DateRangePicker));
