import { createSelector } from 'reselect';
import { RootState } from '../../store';

const selectNotification = (state: RootState) => state.notification;

export const selectNotificationCount= createSelector(
    selectNotification,
    (notification) => Array.from(notification.notificationsMap.values()).filter(value => value.isNew).length,
);

export const selectAllNotification= createSelector(
    selectNotification,
    (notification) => Array.from(notification.notificationsMap.values()),
);

export const selectAllNotificationMapping = createSelector(
    selectNotification,
    (notification) => notification.notificationsMap,
);
