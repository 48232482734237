import React, { useCallback } from 'react';
import {
    MenuItem,
    Button, Menu, withStyles, MenuProps,
} from '@material-ui/core';
import './EditMenu.scss';
import { connect } from 'react-redux';
import { FormActions } from '../../../actions';
import { ISensor } from '../../../interfaces';
import { ReactComponent as MoreIcon } from '../../assets/images/icons/more.svg';

const StyledMenu = withStyles({
    paper: {
        borderRadius: 4,
        boxShadow: '0 2px 6px 0 rgba(33, 37, 41, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.15)',
        backgroundColor: '#ffffff',
    },
    list: {
        padding: '16px 0',
    },

})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(() => ({
    root: {
        '&:focus': {
            backgroundColor: 'transparent',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: 'red',
            },
        },
        fontFamily: '\'IBM Plex Sans\', Arial, Helvetica, sans-serif',
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '32px',
        letterSpacing: 'normal',
        color: '#333333',
        padding: '0 24px',
    },
}))(MenuItem);

/**
 * Edit Menu
 *
 * @param {Object} dataItem
 * @param {Object[]} menuList
 * @param {Object} sensor
 * @param {Object} nodeName
 * @param classNameMenu
 * @param {Object} props
 * @constructor
 *
 * @return {JSX.Element}
 */
const EditMenu = ({ dataItem, menuList, sensor, nodeName, classNameMenu, ...props }: IEditMenu) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    /**
     * Handler to click
     *
     * @param {React.MouseEvent<HTMLElement>} event
     */
    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);

        if (props.onOpen) {
            props.onOpen(event.currentTarget);
            props.toggleForm(true, '');
        }
    }, [props, setAnchorEl]);

    /**
     * Handler to close
     */
    const handleClose = useCallback(() => {

        setAnchorEl(null);

        if (props.onClose) {

            props.onClose(null);
        }
    }, [props, setAnchorEl]);

    const menuItem = (value: any, key: number) => {

        return (
            <StyledMenuItem
                key={key}
                onClick={() => {
                    value.action(dataItem, true, nodeName);
                    handleClose();
                }}
                style={{ color: value.color }}
                disabled={value?.disabled || false}
            >
                {value.title}
            </StyledMenuItem>
        );
    };

    return (
        <React.Fragment>
            <Button
                aria-controls={dataItem.name}
                aria-haspopup="true"
                variant="contained"
                className={'edit-dots'}
                onClick={handleClick}
            >
                <MoreIcon />
            </Button>
            {anchorEl ?
                <StyledMenu
                    className={`edit-dots__menu edit-dots__menu-${dataItem.id} ${classNameMenu}`}
                    id={dataItem.name}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    container={props.container}
                >
                    {menuList.map((value: any, key)=> menuItem(value, key))}
                </StyledMenu>
                : null}

        </React.Fragment>
    );
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = ({
    toggleForm: FormActions.toggle,
});

export default connect(null, mapDispatchToProps)(EditMenu);

interface IEditMenu {
    dataItem: any;
    menuList: Record<string, unknown>[];
    sensor?: ISensor;
    nodeName?: string;
    classNameMenu?: string;
    onClose?: (props: any) => void;
    onOpen?: (props: any) => void;
    toggleForm: (opened: boolean, name?: string) => void;
    container?: any;
}